<template>
    <div>
        <div>
            <div class="content-top">
                <div class="top-part1">
                    <img src="@/assets/imgs/logo.png">
                </div>
                <div class="top-part2">
                    <span class="sloganText">后直播时代风险管控</span>
                    <div class="top-part2-i1">
                        <span>直播风险管控最小范围</span>
                        <span>降低违规带来的营收风险损失、降低账号封禁风险、降低店铺封禁风险</span>
                        <span>持续稳健经营，保驾护航</span>
                    </div>
                </div>
                <img src="@/assets/imgs/solution1.png">
            </div>

            <div class="content-item">
                <div class="item-part">
                    <div class="item-part1">
                        <span>自动录屏</span>
                    </div>
                    <div class="item-part2">
                        <img src="@/assets/imgs/solution_item1.png">
                    </div>
                    <div class="item-part3">
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i1.png" style="width: 30%;">
                                <span>痛点:</span>
                            </div>
                            <div class="part3-i2">
                                <span>1、运营和主播 不能及时和自动化监控直播间</span>
                                <span>2、手机录制费时费力没效率，文件太大清晰度不够，导出麻烦</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i2.png" style="width: 30%;">
                                <span>方案:</span>
                            </div>
                            <div class="part3-i2">
                                <span>PC桌面客户端自动录屏</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i3.png" style="width: 30%;">
                                <span>优势:</span>
                            </div>
                            <div class="part3-i2">
                                <span>1、自动化：不需要人工监控直播间开播情况</span>
                                <span>2、效率高：一台电脑可监控录制多个直播间</span>
                                <span>3、全免费：监控、录屏、下载功能全免费</span>
                                <span>4、很高清：高清原码视频、可直接切片剪辑</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/solution2.png">
            </div>

            <div class="content-item">
                <div class="item-part">
                    <div class="item-part1">
                        <span>自动分析</span>
                    </div>
                    <div class="item-part2">
                        <img src="@/assets/imgs/solution_item2.png">
                    </div>
                    <div class="item-part3">
                        <div>
                            <div  class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i1.png" style="width: 30%;">
                                <span>痛点:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>音视频转译成文字，需长时间等待，费时费力没效率</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i2.png" style="width: 30%;">
                                <span>方案:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>录制完毕即自动转译自动分析</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i3.png" style="width: 30%;">
                                <span>优势:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>1、自动分析，无需等待，下播即可复盘</span>
                                <span>2、无须压缩：不管录制文件大小如何，无须压缩视频再转译</span>
                                <span>3、无须多工具：全部操作在一个产品内完成</span>
                                <span>4、风险提示：违规风险话术高亮提示</span>
                                <span>5、语速分析：快速定位主播表达问题</span>
                                <span>6、主播话术框架分析：内容、动作关键词话术精准分析，提高复盘效率</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/solution3.png">
            </div>
            
            <div class="content-item"> 
                <div class="item-part">
                    <div class="item-part1">
                        <span>违规风险提醒</span>
                    </div>
                    <div class="item-part2">
                        <img src="@/assets/imgs/solution_item3.png">
                    </div>
                    <div class="item-part3">
                        <div>
                            <div  class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i1.png" style="width: 30%;">
                                <span>痛点:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>音视频转译成文字，需长时间等待，费时费力没效率</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i2.png" style="width: 30%;">
                                <span>方案:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>1、词库由风控专家和优秀直播操盘手构建</span>
                                <span>2、多级敏感词分类+多套语义匹配规则+智能语义分析</span>
                                <span>3、实时跟进和研究平台风控动向</span>
                                <span>4、敏感词在话术文本里重点标记</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i3.png" style="width: 30%;">
                                <span>优势:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>1、准确度高：轻度违规词和重度违规词都标记，精准匹配机审和人审规则、适应平台各个阶段的风控烈度</span>
                                <span>2、一目了然：不再需要一个词一个词的去搜索，下播就能看违规</span>
                                <span>3、越用越准：智能分析模块，根据你的直播间情况，越用越精准</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/solution4.png">
            </div>

            <div class="content-item">
                <div class="item-part">
                    <div class="item-part1">
                        <span>主播话术内容框架分析</span>
                    </div>
                    <div class="item-part2">
                        <img src="@/assets/imgs/solution_item4.png">
                    </div>
                    <div class="item-part3">
                        <div>
                            <div  class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i1.png" style="width: 30%;">
                                <span>痛点:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>全场逐字逐句复盘费时费力，初级主播和运营，复盘和对标没有重点、没有标准</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i2.png" style="width: 30%;">
                                <span>方案:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>关键词是直播话术框架中的关键点，复盘关键词就是复盘话术框架关键点</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i3.png" style="width: 30%;">
                                <span>优势:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>1、效率高：复盘重点一目了然、主播和运营更乐意复盘</span>
                                <span>2、有标准：主播和运营统一直播标准，增加互信和协作程度</span>
                                <span>3、增强基础数据意识：提炼和标注直播中粉、团、群、互动、人设、种草、逼单等关键词的时间、频次等，提高主播和运营做基础数据的意识</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/solution5.png">
            </div>

            <div class="content-item">  
                <div class="item-part">
                    <div class="item-part1">
                        <span>对比分析</span>
                    </div>
                    <div class="item-part2">
                        <img src="@/assets/imgs/solution_item5.png">
                    </div>
                    <div class="item-part3">
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i1.png" style="width: 30%;">
                                <span>痛点:</span>
                            </div>
                            <div class="part3-i2">
                                <span>和优秀主播没有对比，不知道哪里不好运营给主播复盘没有画面对比，复盘主播听不懂对标直播间，不知道对标那些东西，复盘无效果</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i2.png" style="width: 30%;">
                                <span>方案:</span>
                            </div>
                            <div class="part3-i2">
                                <span>对比复盘分析</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i3.png" style="width: 30%;">
                                <span>优势:</span>
                            </div>
                            <div class="part3-i2">
                                <span>1、统一标准：画面、话术、声音、语气、语速、关键词和关键词密度均可对比</span>
                                <span>2、一目了然：通过对比，什么时间点做了什么，相同和不同点一目了然</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/solution6.png">
            </div>

            <div class="content-item">
                <div class="item-part">
                    <div class="item-part1">
                        <span>在线复盘</span>
                    </div>
                    <div class="item-part2">
                        <img src="@/assets/imgs/solution_item6.png">
                    </div>
                    <div class="item-part3">
                        <div>
                            <div  class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i1.png" style="width: 30%;">
                                <span>痛点:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>异地协作复杂、没有统一标准</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i2.png" style="width: 30%;">
                                <span>方案:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>录制分析后，上传到云端，方便协作</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i3.png" style="width: 30%;">
                                <span>优势:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>1、统一运营和主播标准，异地协作好沟通</span>
                                <span>2、优秀的场次和复盘内容存储在云端，不怕丢失</span>
                                <span>3、多个主播之间互相学习更方便</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/solution7.png">
            </div>

            <div class="content-item">   
                <div class="item-part">
                    <div class="item-part1">
                        <span>在线复盘</span>
                    </div>
                    <div class="item-part2">
                        <img src="@/assets/imgs/solution_item7.png">
                    </div>
                    <div class="item-part3">
                        <div >
                            <div  class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i1.png" style="width: 30%;">
                                <span>痛点:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>平台规则变化太快、大部分团队缺少风控部门</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i2.png" style="width: 30%;">
                                <span>方案:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>爱复盘风控专家提供专业服务</span>
                            </div>
                        </div>
                        <div>
                            <div class="part3-i1">
                                <img src="@/assets/imgs/solution_item_i3.png" style="width: 30%;">
                                <span>优势:</span>
                            </div>
                            <div  class="part3-i2">
                                <span>1、快速定位违规类型和节点</span>
                                <span>2、快速适应平台规则</span>
                                <span>3、业务经营更持续、主播播得更安心</span>
                                <span>4、降低因违规产生的停播、封禁、封号等损失</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/solution8.png">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>

<style scoped>

    img{
        width: 100%;
    }

    .content-top{
        position: relative;
        display: flex;
        justify-content: center;
    }
    .top-part1{
        position: absolute;
        margin-top: 10px;
        left: 15%;
        width: 8%;
    }
    .top-part2{
        position: absolute;
        top: 10.7vw;
        left: 18.8%;
        width: 100%;
        color: #2B2E33;
        font-size: 1vw;
    }
    .top-part2-i1{
        display: flex;
        gap: 0.62vw;
        color: #2B2E33;
        font-size: 1vw;
        flex-direction: column;
        margin-top: 2.23vw;
    }

    .content-item{
        position: relative;
        display: flex;
    }
    .item-part{
        position: absolute;
        left: 18.72vw;
        top: 6.15vw;
    }
    .item-part1{
        color: #2B2E33;
        font-size: 2.08vw;
    }
    .item-part2{
        margin: 2.9vw 0px 3.6vw 0px;
        width: 4.47vw;
    }
    .item-part3{
        display: flex;
        gap: 1.45vw;
        flex-direction: column;
        width: 23.5vw;
    }

    .part3-i1{
        display: flex;
        gap: 0.4vw;
        align-items: center;
        color: #2F3742;
        font-size: 1.05vw;
        font-weight: bold;
        width: 20%;
    }
    .part3-i2{
        color: #464D5C;
        font-size: 0.83vw;
        margin-top: 0.62vw;
        display: flex;
        flex-direction: column;
    }

    .sloganText{
        font-size: 3vw;
        background: linear-gradient(90deg, #04ADFF 0%, #004AFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }
</style>