<template>
    <div>
        <div>
            <div class="content">
                <div class="content-item1">
                    <img src="@/assets/imgs/logo.png">
                </div>
                <div class="item1-part">
                    <span class="sloganText">爱复盘</span>
                    <div class="item1-part-i1">国内首款直播内容智能复盘分析工具</div>
                    <div class="item1-part-i2">免费自动录屏 再也不用担心错过优秀直播场次啦！</div>
                    <div class="home-download" @click="download">下载Windows版本</div>
                    <div class="item1-part-i3">仅支持Windows10以上系统</div>
                    <div class="item1-part-i4">注意：下载安装前，请务必关掉电脑上的360杀毒软件或其他杀毒软件，以防误报</div>
                </div>
                <img src="@/assets/imgs/home_page1.png">
            </div>

            <div class="content">
                <div class="item2-part1">
                    <span>核心优势</span>
                    <video class="video-style" :src="videoSrc" controls ></video>
                </div>
                <div class="item2-part2" style="left: 19vw;">
                    <img src="@/assets/imgs/home_page2_1.png">
                    <span class="item2-part2-i1">提升流量、促转化</span>
                </div>
                <div class="item2-part2" style="left: 40vw;">
                    <img src="@/assets/imgs/home_page2_2.png">
                    <span class="item2-part2-i1">增加盈利、降成本</span>
                </div>
                <div class="item2-part2" style="right: 18.5vw;">
                    <img src="@/assets/imgs/home_page2_3.png">
                    <span class="item2-part2-i1">降低违规风险</span>
                </div>
                <img src="@/assets/imgs/home_page2.png">
            </div>
            
            <div class="content">
                <div class="item3-part1">
                    <span>产品功能</span>
                </div>
                <div class="item3-part2" style="left: 19vw;">
                    <img src="@/assets/imgs/home_page3_1.png">
                    <span class="item3-part2-i1">爱复盘替代人工录制</span>
                </div>
                <div class="item3-part2" style="left: 40vw;">
                    <img src="@/assets/imgs/home_page3_2.png">
                    <span class="item3-part2-i2" style="">
                        <div>简化运营、主播、剪辑</div>
                        <div>繁琐工作细节</div>
                    </span>
                </div>
                <div class="item3-part2" style="right: 18.5vw;">
                    <img src="@/assets/imgs/home_page3_3.png">
                    <span class="item3-part2-i3">
                        <div>提高工作效率</div>
                        <div>公司整体增效</div>
                    </span>
                </div>
                <img src="@/assets/imgs/home_page3.png">
            </div>
            
            <div class="content">
                <div class="plate">
                    <div class="plate-part1">
                        
                        <div class="plate-part1-i1">
                            <img src="@/assets/imgs/home_page4_1.png" style="width: 20%;">
                            <span style="margin-left: 1%;">基础板块</span>
                        </div>

                        <div>分析板块</div>
                        <div>风险防控</div>
                    </div>
                    <div class="plate-part2">
                        <span>自动录屏，高清源码</span>
                        <div class="plate-part2-i1" style="width: 10.7vw;"></div>
                    </div>
                </div>
                <img src="@/assets/imgs/home_page4.png">
            </div>

            <div class="content">
                <div class="plate">
                    <div class="plate-part1">
                        <div>基础板块</div>

                        <div class="plate-part1-i1">
                            <img src="@/assets/imgs/home_page4_2.png" style="width: 18%;">
                            <span style="margin-left: 1%;">分析板块</span>
                        </div>

                        <div>风险防控</div>
                    </div>
                    <div class="plate-part2">
                        <span>自动分析、违规风险提醒、对比分析、在线分析</span>
                        <div class="plate-part2-i1" style="width: 26.5vw;"></div>
                    </div>
                </div>
                <img src="@/assets/imgs/home_page5.png">
            </div>

            <div class="content">
                <div class="plate">
                    <div class="plate-part1">
                        <div>基础板块</div>
                        <div>分析板块</div>

                        <div class="plate-part1-i1">
                            <img src="@/assets/imgs/home_page4_3.png" style="width: 17.5%;">
                            <span style="margin-left: 1%;">风险防控</span>
                        </div>

                    </div>
                    <div class="plate-part2">
                        <span>播前分析、违规风险管控、专家服务</span>
                        <div class="plate-part2-i1" style="width: 20.6vw;"></div>
                    </div>
                </div>
                <img src="@/assets/imgs/home_page6.png">
            </div>


            <div class="home-page7">
                <img src="@/assets/imgs/home_page7.png">
                <div style="position: absolute;top: 6.66vw;left: 20.5vw;">产品介绍</div>
                <div class="home-page7-Text">
                    <div>爱复盘是中国首款一站式、多平台、专业直播内容复盘分析工具。</div>
                    <div>作为第一款由主播、操盘手、风控、互联网产品、开发共同打造的内容复盘分析工具，爱复盘提供了自动录屏、自动分析、语速分析、违规风险提示、对比分析、在线分析、播前分析、违规风险管控、本地词库、专家服务等一站式直播内容智能复盘分析服务，能够帮助直播公司和团队大幅简化主播、运营、剪辑、编辑等核心岗位的工作流程，降低直播间命中违禁词的频率、提高主播和运营的复盘能力、异地协同能力、直播素材和复盘知识沉淀的能力。</div>
                    <div>爱复盘的基础功能版块，提供了从直播录制、视频多种清晰度选择、本地视频存储、转译视频成文字等一站式服务。智能复盘分析板块，帮助直播团队更好的理解和定位直播的关键节点和关键行为，不仅可以和<span>优秀</span>直播间对比，还可以同一个直播间对比，以提升直播间的核心数据，从而获得更高的流量和转化。</div>
                    <div>风险管控板块，由专业的风控专家和顶级操盘手构建，敏感词准确率达到70%，覆盖了电商、知识付费、本地生活、娱乐PK、大健康等<span>1200多个细分行业和1000种违规细分场景</span>。</div>
                    <div>既可以开播前进行敏感词分析，还可以下播后分析，为新老主播、运营快速定位违规风险点，企业招聘和培训、提供了强有力的参考。</div>
                    <div>定制化服务板块上，我们提供了本地词库功能和专家服务，为直播公司提供更个性化的运营拓展空间。</div>
                    <div>爱复盘是一款有核心方法论的智能复盘分析工具，公司创始人和创始团队在零基础主播培养、赛道快速复制，直播玩法创新上，均具备<span>较高</span>行业知名度。</div>
                    <div>在创办5年的时间里，打造过30多个万人在线直播间，10多个直播间起号一周内就达到10万+场观，在多个细分赛道里短时间内做到行业第一。</div>
                    <div>我们结合内部运营方法论，<span>又提炼了</span>100+头部直播公司的操盘手和500个万人在线直播间的复盘方法，让复盘不再流于形式和表面，从而快速的提升主播的直播能力和运营的分析总结能力，帮助直播公司获取更多的流量和转化</div>
                    <div>在后直播时代，比拼的是专业能力和精细化运营能力，提升复盘效率和复盘效果，已经是决定直播生死的基本生命线。</div>
                    <div>直播行业的从业者，唯有提高人效、提升专业度，才能在瞬息万变的直播时代更好的生存和发展！<br>
                        <span>数据只是结果，内容才是核心，复盘解决根本！做直播，向复盘，要未来！</span>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="item4-part1">
                    <div class="item4-part1-i1">
                        <span>定制化服务</span>
                        <div class="item4-part1-i1-1"></div>
                    </div>
                </div>
                <div class="item4-part2">
                    <img src="@/assets/imgs/home_page8_1.png" >
                    <span class="item4-part2-i1">本地词库</span>
                </div>
                <img src="@/assets/imgs/home_page8.png">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            videoSrc:"https://download.aifupan.com.cn/promotion.mp4",
            installerUrl:'https://download.ifupan.com/aifupan2.0.0.exe',
            installerName:'爱复盘',
        };
    },

    mounted() {

    },

    methods: {
        download(){
            const link = document.createElement('a');
            link.href = this.installerUrl;
            link.download = this.installerName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
};
</script>

<style scoped>

    img{
        width: 100%;
    }

    .content{
        position: relative;
        display: flex;
        justify-content: center;
    }
    .content-item1{
        position: absolute;
        margin-top: 10px; 
        left: 15%;
        width: 8%;
    }
    .item1-part{
        position: absolute;
        bottom: 2%;
        left: 18.8%;
        width: 100%;
        color: #2B2E33;
        font-size: 1vw;
    }
    .item1-part-i1{
        margin-top: 2.4vw;
    }
    .item1-part-i2{
        margin: 0.7vw 0px 1.6vw 0px;
    }
    .item1-part-i3{
        color: #687583;
        font-size: 0.84vw;
        margin: 1vw 0px 0.84vw 0px;
    }
    .item1-part-i4{
        color: #EF523E;
        font-size: 0.84vw;
    }

    .item2-part1{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 3.1vw 0px 2.6vw 0px;
        color: #2B2E33;
        font-size: 2.08vw;
    }
    .item2-part2{
        position: absolute;
        bottom: 2vw;
        width: 20%;
        color: #FFFFFF;
        font-size: 1.25vw;
    }
    .item2-part2-i1{
        position: absolute;
        bottom: 1.7vw;
        left: 6.3vw;
    }
    
    .item3-part1{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #2B2E33;
        font-size: 2.08vw;
        padding: 6.5vw 0px 0px 0px;
    }
    .item3-part2{
        position: absolute;
        bottom: 10vw;
        width: 20%;
        color: #2B2E33;
        font-size: 1.25vw;
    }
    .item3-part2-i1{
        position: absolute;
        bottom: 1.7vw;
        left: 4vw;
    }
    .item3-part2-i2{
        position: absolute;
        bottom: 1vw;
        left: 4vw;
        text-align: center;
    }
    .item3-part2-i3{
        position: absolute;
        bottom: 1vw;
        left: 6vw;
    }

    .plate{
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 3.8vw;
    }
    .plate-part1{
        display: flex;
        align-items: center;
        color: #464D5C;
        font-size: 1.35vw;
        gap: 8.3vw;
        margin-bottom: 5.15vw;
    }
    .plate-part1-i1{
        color: #FFFFFF;
        font-size: 1.45vw;
        padding: 0.95vw;
        display: flex;
        align-items: center;
        background: linear-gradient( 90deg, #04ADFF 0%, #004AFF 100%);
        border-radius: 0.83vw;
    }
    .plate-part2{
        color: #2B2E33;
        font-size: 1.5vw;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .plate-part2-i1{
        color: #0059FF;
        height: 1.1vw;
        margin-top: -0.52vw;
        background: rgba(0,89,255,0.1);
    }

    .item4-part1{
        position: absolute;
        justify-content: center;
        align-items: center;
        padding-top: 4.3vw;
    }
    .item4-part1-i1{
        color: #2B2E33;
        font-size: 1.5vw;
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .item4-part1-i1-1{
        color: #0059FF;
        width: 6.7vw;
        height: 1.1vw;
        margin-top: -0.52vw;
        background: rgba(0,89,255,0.1);
    }

    .item4-part2{
        position: absolute;
        bottom: 3.1vw;
        width: 11.25vw;
    }
    .item4-part2-i1{
        position: absolute;
        top: 1vw;
        left: 2.65vw;
        color: #FFFFFF;
        font-size: 1.25vw;
    }
    
    .sloganText{
        font-size: 2.8vw;
        font-weight: bold;
        background: linear-gradient(90deg, #04ADFF 0%, #004AFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .video-style{
        width: 32vw;
        height: 44.5%;
        border: 0.5px solid #eee;
        margin: 2.08vw 0px;
    }

    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }

    .home-download{
        width: 12vw; 
        border-radius: 8px;
        font-size: 1.1vw;
        background-color: #0077FF;
        color: #FFFFFF;
        text-align: center;
        line-height: 2.5vw;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }

    .home-download:hover{
        transform: translateY(-3px);
        color: #6ee6fe;
    }

    .home-page7{
        position: relative;
        display: flex;
        color: #2B2E33;
        font-size: 2.08vw;
    }

    .home-page7-Text{
        position: absolute;
        top: 26.5%;
        left: 20.5%;
        width: 60%;
        color: #2B2E33;
        font-size: 0.95vw;
        height: 61%;
        overflow-y: auto;
    }

    .home-page7-Text div{
        margin-bottom: 15px;
        line-height: 1.8
    }

    .home-page7-Text span{
        color: red;
    }

    ::v-deep .home-page7-Text::-webkit-scrollbar {
        width: 17px;
    }

    ::v-deep .home-page7-Text::-webkit-scrollbar-thumb {
        background: #ddd;
        border-radius: 8px;
    }
</style>