<template>
    <div>
        <div>
            <div class="content">
                <div class="content-logo">
                    <img src="@/assets/imgs/logo.png">
                </div>
                <div class="content-top">
                    <span>关于我们</span>
                </div>
                <img src="@/assets/imgs/about_us1.png">
            </div>

            <div class="content">
                <div class="content-item1">公司简介</div>
                <div class="item1-part1">
                        <div>集团背景</div>
                        <div>背靠上海鲸游云上集团，成立于2019年。旗下有头部知识付费直播公司，</div>
                        <div>为【爱复盘】提供海量云案例和方法论；</div>
                        <div>公司专注于互联网科技领域，致力于为用户提供内容复盘分析工具。由资深互</div>
                        <div>联网产品专家、技术专家、直播运营操盘手、风控专家共同打造；</div>
                        <div>公司创始人和创始团队在素人主播培养、赛道快速复制，直播玩法创新上，在</div>
                        <div>业内均具备较高知名度；</div>
                        <div>公司理念</div>
                        <div>做直播，向复盘，要未来！</div>
                        <div>产品定位</div>
                        <div>数据只是结果，内容才是核心，复盘解决根本爱复盘，专业的直播内容复盘分析工具</div>
                        <div>爱复盘，专业的直播内容复盘分析工具</div>
                </div>
                <div class="item1-part2">
                    <img src="@/assets/imgs/about_us2_item1.png">
                </div>
                <img src="@/assets/imgs/about_us2.png">
            </div>

            <div class="content">
                <div class="content-item2">
                    <div class="item2-part1">
                        <span>直播数据</span>
                    </div>

                    <div class="item2-part2">
                        <div class="item2-part2-i1">
                            <div class="item2-part2-i1-text">
                                <span class="figures">20个</span>
                                <span>万人在线直播间</span>
                            </div>
                            <div class="item2-part2-i1-text">
                                <span class="figures">8亿</span>
                                <span>2023公司场播数据</span>
                            </div>
                        </div>
                        <div class="item2-part2-i1">
                            <div class="item2-part2-i1-text">
                                <span class="figures">3亿</span>
                                <span>2022公司场播数据</span>
                            </div>
                            <div class="item2-part2-i1-text">
                                <span class="figures">12亿</span>
                                <span>2024公司场播数据</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/about_us3.png">
            </div>
            
            <div class="content">
                <div class="content-item2">
                    <div class="item3-part1">
                        <span>里程碑</span>
                    </div>
                </div>

                <div class="item3-part2" style="top: 17.58vw;left: 20.5vw;">
                    <span class="item3-part3">2019.10</span>
                    <span>上海总公司成立</span>
                </div>

                <div class="item3-part2" style="top: 15.8vw;left: 37.5vw;">
                    <span class="item3-part3">2021.3</span>
                    <span>成都分公司成立</span>
                </div>

                <div class="item3-part2" style="top: 14.58vw;right: 37vw;">
                    <span class="item3-part3">2023.1</span>
                    <span>长沙分公司成立</span>
                </div>

                <div class="item3-part2" style="top: 13.33vw;right: 20.5vw;">
                    <span class="item3-part3">2023.3</span>
                    <span>西安分公司成立</span>
                </div>

                <img src="@/assets/imgs/about_us4.png">
            </div>
            
            <div class="content">

                <div class="content-item4">
                    <div class="item4-part1">
                        <span>联系我们</span>
                    </div>
                    <div class="item4-part2">
                        <img src="@/assets/imgs/about_us5_item1.png" style="width: 120%;">
                        <span>详情咨询请扫二维码</span>
                    </div>
                </div>
                <img src="@/assets/imgs/about_us5.png">
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },

    mounted() {

    },

    methods: {

    },
};
</script>
<style scoped>

    img{
        width: 100%;
    }

    .content{
        position: relative;
    }
    .content-logo{
        position: absolute;
        margin-top: 10px;
        left: 15%;
        width: 8%;
    }
    .content-top{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #FFFFFF;
        font-size: 3.95vw;
        padding: 1.35vw 2.44vw;
        top: 14vw;
        left: 39.5vw;
        justify-content: center;
        border: 3px solid white;
    }

    .content-item1{
        position: absolute;
        top: 6.66vw;
        left: 20.5vw;
        color: #2B2E33;
        font-size: 2.08vw;
    }
    .item1-part1{
        position: absolute;
        top: 26.5%;
        left: 20.5%;
        color: #2B2E33;
        font-size: 0.95vw;
        line-height: 2.08vw;
    }
    .item1-part2{
        position: absolute;
        top: 9.58vw;
        right: 19vw;
        width: 25%;
    }

    .content-item2{
        position: absolute;
        top: 4vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 9vw;
    }
    .item2-part1{
        font-size: 1.8vw;
        color: #2B2E33;
        font-weight: bold;
    }
    .item2-part2{
        display: flex;
        width: 100%;
        justify-content: center;
        gap:19.16vw;
    }
    .item2-part2-i1{
        display: flex;
        flex-direction: column;
        gap: 6.83vw;
    }
    .item2-part2-i1-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.05vw;
        font-size: 1.2vw;
        color: #2B2E33;
    }
    .figures{
        font-size: 2.5vw;
        font-weight: bold;
        background: linear-gradient(90deg, #04ADFF 0%, #004AFF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .item3-part1{
        font-size: 1.66vw;
        color: #2B2E33;
        font-weight: bold;
    }
    .item3-part2{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.2vw;
        color: #2B2E33;
        gap: 0.91vw;
    }
    .item3-part3{
        color: #0077FF;
        font-size: 1.85vw;
        font-weight: bold;
    }

    .content-item4{
        position: absolute;
        top: 4vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2.12vw;
    }
    .item4-part1{
        font-size: 2vw;
        color: #2B2E33;
        font-weight: bold;
    }
    .item4-part2{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5vw;
        font-size: 0.86vw;
        width: 9.16vw;
    }

    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }
    
    

</style>