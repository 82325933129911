import { render, staticRenderFns } from "./about-us.vue?vue&type=template&id=532382e7&scoped=true"
import script from "./about-us.vue?vue&type=script&lang=js"
export * from "./about-us.vue?vue&type=script&lang=js"
import style0 from "./about-us.vue?vue&type=style&index=0&id=532382e7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532382e7",
  null
  
)

export default component.exports