<template>
    <div>
        <div>
            <div class="content">
                <div class="content-logo">
                    <img src="@/assets/imgs/logo.png">
                </div>
                <div class="content-top">
                    <span class="top-part1">下载爱复盘客户端</span>
                    <div class="top-part2">
                        <span>爱复盘、专业的直播复盘分析工具。让主播和运营复盘效率原地起飞！</span>
                    </div>
                    <img src="@/assets/imgs/download1_item1.png" style="width: 38vw;">
                    <div class="top-part3">注意：下载安装前，请务必关掉电脑上的360杀毒软件或其他杀毒软件，以防误报</div>
                    <div  @click="download" class="top-part4">
                        <hover style="width: 100%"></hover>
                    </div>
                    <div class="top-part5">仅支持Windows10以上系统</div>
                </div>
                <img src="@/assets/imgs/download1.png">
            </div>

            <div class="content">
                <div class="content-item1">
                    <div class="item1-part1">
                        <span>硬件配置要求</span>
                    </div>
                    <div class="item1-part2">
                        <div class="item1-part2-i1">
                            <img src="@/assets/imgs/download2_item1.png" >
                            <div class="item1-part2-i2">
                                <span class="item1-part2-i3">操作系统:</span>
                                <span>Windows10 专业版</span>
                            </div>
                        </div>

                        <div class="item1-part2-i1">
                            <img src="@/assets/imgs/download2_item3.png">
                            <div class="item1-part2-i2">
                                <span class="item1-part2-i3">Windows电脑</span>
                                <span>CPU 15 4570(3.6GHz)以上，笔记本电脑15 7300U(2.6GHz)及以上</span>
                            </div>
                        </div>
                        <div class="item1-part2-i1">
                            <img src="@/assets/imgs/download2_item2.png">
                            <div class="item1-part2-i2">
                                <span class="item1-part2-i3">内存</span>
                                <span>极速模式4G、普通以及高清模式8G</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="item-part3">
                    <span>本软件不支持mac电脑，请使用Windows电脑</span>
                </div>
                <img src="@/assets/imgs/download2.png">
            </div>

            <div class="content">
                <div class="content-item2">
                    <div class="item2-part1">
                        <img src="@/assets/imgs/download3_item1.png" style="width: 12%;">
                        <span>注意事项</span>
                    </div>
                    <div class="item2-part2">
                        <span>安装过程中，请右击，选择使用超级管理员安装</span>
                    </div>
                    <div class="item2-part3">
                        <div class="item2-part3-i1">
                            <img src="@/assets/imgs/download3_item2.png">
                            <div class="item2-part3-i2">
                                <span>1、爱复盘安装升级程序 会帮助你快捷安装或升级</span>
                            </div>
                        </div>
                        <div class="item2-part3-i1">
                            <img src="@/assets/imgs/download3_item2.png">
                            <div class="item2-part3-i2">
                                <span>2、如果你之前没安装过，程序会为你进行全新安装爱复盘直播录屏。</span>
                            </div>
                        </div>
                        <div class="item2-part3-i1">
                            <img src="@/assets/imgs/download3_item2.png">
                            <div class="item2-part3-i2">
                                <span>3、如果之前安装过旧版，建议把此程序放置在旧版文件夹内，运行会自动检测更新升级。</span>
                            </div>
                        </div>
                        <div class="item2-part3-i1">
                            <img src="@/assets/imgs/download3_item2.png">
                            <div class="item2-part3-i2">
                                <span>4、如果当前正在运行爱复盘录屏，程序会自动结束并更新升级，待升级完成后，打开即可。</span>
                            </div>
                        </div>
                        <div class="item2-part3-i1">
                            <img src="@/assets/imgs/download3_item2.png">
                            <div class="item2-part3-i2">
                                <span>5、无论是安装还是升级，都会为你创建或更新桌面快捷方式 “爱复盘”，方便你使用。</span>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="@/assets/imgs/download3.png">
            </div>

        </div>
    </div>
</template>

<script>
import hover from '../hover.vue';
export default {
    components:{hover},
    data() {
        return {
            installerUrl:'https://download.ifupan.com/aifupan2.0.0.exe',
            installerName:'爱复盘',
        };
    },
    methods: {
        download(){
            const link = document.createElement('a');
            link.href = this.installerUrl;
            link.download = this.installerName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

    },
};
</script>
<style scoped>

    img{
        width: 100%;
    }
    .content{
        position: relative;
    }
    .content-logo{
        position: absolute;
        margin-top: 10px; 
        left: 15%;
        width: 8%;
    }
    .content-top{
        position: absolute;
        bottom: 0%;
        width: 100%; 
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .top-part1{
        color: #2B2E33;
        font-size: 2vw;
    }
    .top-part2{
        color: #687583;
        font-size: 1.05vw;
        margin: 0.85vw 0vw 2.4vw 0vw;
    }
    .top-part3{
        color: #EF523E;
        font-size: 0.84vw;
        margin: 0.9vw 0px;
    }
    .top-part4{
        width: 24%;
        border-radius: 16px;
    }
    .top-part5{
        color: #687583;
        font-size: 0.84vw;
        margin: 1vw 0px 2.2vw 0px;
    }

    .content-item1{
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .item1-part1{
        margin: 4vw 0vw 3.5vw 0vw;
        color: #2B2E33;
        font-size: 2vw;
    }


    .item1-part2{
        display: flex;
        gap: 8.5vw;
    }
    .item1-part2-i1{
        width: 15vw;
    }
    .item1-part2-i2{
        display: flex;
        flex-direction: column;
        gap: 0.78vw;
        margin: 1.5vw 0vw 0vw 1.3vw;
        width: 11.45vw;
        color: #2B2E33;
        font-size: 0.85vw;
    }
    .item1-part2-i3{
        font-weight: bold;
        font-size: 1.05vw;
    }


    .item-part3{
        position: absolute;
        bottom: 2.81vw;
        left: 19vw;
        color: #687583;
        font-size: 0.85vw;
    }

    .content-item2{
        position: absolute;
        top: 4.42vw;
        left: 19vw;
    }
    .item2-part1{
        color: #2B2E33;
        font-size: 1.25vw;
        display: flex;
        align-items: center;
        gap: 12px;
        font-weight: bold;
        width: 20%;
    }
    .item2-part2{
        color: #687583;
        font-size: 0.85vw;
        margin: 1vw 0vw 0.85vw 0vw;
    }
    .item2-part3{
        display: flex;
        background-color: #F4F9FF;
        padding: 1.71vw 2.6vw 2.1vw 2.6vw;
        gap: 3.33vw;
    }
    
    .item2-part3-i1{
        width: 9.5vw;
    }
    .item2-part3-i2{
        color: #2B2E33;
        font-size: 0.85vw;
        margin-top: 1.05vw;
        line-height: 1.6
    }
    .item2-part3-i1 img{
        width: 1.6vw;
    }

    .msg{
        background-color: #F6F7F9;
        display: flex;
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
        padding: 24px 0px;
        color: #666666;
        font-size: 14px;
    }

    .download-btn{
        transition: all linear 0.3s;
    }

    .download-btn:hover{
        cursor: pointer;
        box-shadow: 0 6px 20px rgba(12, 100, 242);
        transform: translateY(-3px);
    }
</style>